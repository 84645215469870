import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { cn } from "@/lib/utils";
import { LayoutDashboard, User } from 'lucide-react';

const Navbar = ({ user }) => {
  const location = useLocation();
  const navItems = [
    { name: 'Dashboard', path: '/', icon: LayoutDashboard },
    { name: 'Account', path: '/account', icon: User },
  ];

  return (
    <nav className="flex justify-end items-center w-full bg-background p-2 pr-6">
      <div className="bg-muted rounded-lg p-1 flex">
        {navItems.map((item) => (
          <Link
            key={item.name}
            to={item.path}
            className={cn(
              "flex items-center p-2 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 rounded-md",
              location.pathname === item.path
                ? "text-foreground"
                : "text-muted-foreground",
              "hover:text-foreground"
            )}
          >
            <item.icon className="w-5 h-5 mr-2" />
            <span>{item.name}</span>
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;